import React from 'react'
import Heading from "../components/Heading"
import SEO from '../components/SEO';

function Uteomraader() {
  return (
    <div className='content-wrapper'>
      <SEO title="Uteområder" />
      <Heading heading={"Uteområder"}/>
    {/* <Subheading subHeading={"Hageparsell"}/>
    <Subheading subHeading={"Platting"}/>
    <Subheading subHeading={"Beplanting"}/>
    <Subheading subHeading={"Hageavfall"}/>
    <Subheading subHeading={"Treplantings - plan"}/> */}
    </div>
  )
}

export default Uteomraader;